<template>
  <div class="warp">
    <div class="cententbox" ref="pageh">
      <!-- <div class="persontextbox disflex flexspan">
                <span class="persontext">SOS位置</span>
            </div> -->
      <div class="heaedercl disflex flexal">
        <div>
          <div class="fhText mouse" @click="backbtn">返回</div>
        </div>
        <div class="head">
          <div></div>
          <!-- <div class="flex">
            <div class="flex head_dropDown">
              <span>群组</span>
              <el-select class="head_select" v-model="groupId" placeholder="请选择群组">
                <el-option v-for="item in group" :key="item.Cg_ID" :label="item.Cg_Name" :value="item.Cg_ID">
                </el-option>
              </el-select>
            </div>
            <div class="flex head_dropDown">
              <span>发送人</span>
              <el-select class="head_select" v-model="userId" placeholder="请选择发送人">
                <el-option v-for="item in user" :key="item.User_ID" :label="item.User_Name" :value="item.User_ID">
                </el-option>
              </el-select>
            </div>
            <div class="head_search head_dropDown" @click="search()">
              搜索
            </div> -->
          <div class="head_but mouse" v-if="isShow" @click="beginEnd">
            处理
          </div>
          <!-- </div> -->
        </div>
      </div>

      <div class="baidu-api" id="allmap"></div>
    </div>
  </div>
</template>
<script></script>
<script>
  import {queryUserName,queryGroupName} from "@/administration/history.js";
  import axios from "axios";
  // import qs from 'qs';
  const userState = JSON.parse(sessionStorage.getItem("userState"));
  import beasconfig from '@/api/beasconfig.js';
  export default {
    // props: {
    //     ossMap:{
    //         default:''
    //     }
    // },
    data() {
      return {
        sosDetails: {},//经纬度数据
        datasoss:{},
        address:'',
        userInfo:{}, //用户信息
        group:[     //群组
                {
                    value:1,
                    label:'群组1'
                }
            ],
        groupId:'',   //群组id
        user:[     //用户
                {
                    value:1,
                    label:'群组1'
                }
          ],
         userId:'',   //用户id
         isShow:false,
         routeName:null
      };
    },
    mounted() {
        this.into(1);
    },
    methods: {
      into(type){
            let that = this;
            that.sosDetails = that.ossMap.sos_location_at; //经纬度
            const fname = that.ossMap.sendname; //名称
            const cgname = that.ossMap.cg_name; //群组名称
            const time = that.ossMap.time; //时间
            const {lon,lat}= that.sosDetails.baidu;
            if(type == 1){
              that.baiduMap([lon,lat]);
            }
            that.setMarker([lon,lat],fname,time,cgname)
      },
      /*百度地图*/
      baiduMap(pt) {
        var that = this;
        var point = new BMap.Point(...pt);  // 创建点坐标
        var map = new BMap.Map('allmap');
        map.centerAndZoom(point,10);
        map.enableScrollWheelZoom()
        // var address = '';
        //
        // var groc = new BMap.Geocoder();
        //
        // groc.getLocation(point,function(rs){
        //   var addComp = rs.addressComponents;
        //       address = addComp.province + addComp.city + addComp.street + addComp.streetNumber;
        //       console.log('------',address)
        // });

        // setTimeout(function (){
        //   console.log('address99',address)
        // },200)

        /*比例尺*/
        map.addControl(new BMap.NavigationControl({
          type:BMAP_NAVIGATION_CONTROL_LARGE,
          anchor: BMAP_ANCHOR_BOTTOM_LEFT,
          offset: new BMap.Size(20, 50)
        }));// 添加比例尺控件

        this.map=map
      },
      /*创建定位*/
      setMarker(point,fname,tiem,cgname){
        var groc = new BMap.Geocoder();
        var pt = new BMap.Point(...point);
        var that = this;
        var address = ''; //保存经纬度转地址
        that.map.clearOverlays();   //清除覆盖物
        /*改=====================================================开*/
        var opts = {
          position: new BMap.Point(...point), // 指定文本标注所在的地理位置
          offset: new BMap.Size(-160, -220) // 设置文本偏移量
          // 180
        };
        console.log(opts)
        if(cgname == null || cgname == ""){
          cgname = "临时群组";
        }
        groc.getLocation(pt,function(rs){
          // var addComp = rs.addressComponents;
          //  var address = addComp.province + addComp.city + addComp.street + addComp.streetNumber;
           var address = rs.address + rs.surroundingPois[0].title;
          var contentHtml = '';
          contentHtml += '<div class="baiduinfo_box" ><div class="baiduinfo" >';
          contentHtml += '<div><span class="boldText">名称:</span> '+fname+'</div>';
          contentHtml += '<div><span class="boldText">群组:</span> '+cgname+'</div>';
          contentHtml += `<div class="flex-al htmls"><span class="boldText">地址:</span><div class="boldText1" > ${address}</div></div>`;
          // <div class="show">${address}</div> 
          contentHtml += '<div><span class="boldText">时间:</span> '+tiem+'</div>';
          if(that.ossMap.sosStatus == 0){
            contentHtml += '<div class="boldText" >状态: <span style="color: red;" >未处理</span></div>';
          }else{
            contentHtml += '<div class="boldText" >状态: <span>已处理</span></div>';
          }
          contentHtml += '<div class="hint">温馨提示：数据来源第三方，会存在误差，仅供参考</div>';
          contentHtml += '</div> </div>';
          var label = new BMap.Label(contentHtml, opts);
          // 自定义文本标注样式
          label.setStyle({
            color: '#fff',
            borderRadius: '5px',
            borderColor: 'transparent',
            backgroundColor: 'transparent',
            padding: '0px',
            fontSize: '16px',
            fontFamily: '微软雅黑',
            border:'1px solid #e2e2e2'
          });
          that.map.addOverlay(label);
        });
        /*改=====================================================完*/
        /*自定义图标*/
        var myIcon = new BMap.Icon(require('../../assets/history/ckSOS.png'), new BMap.Size(79, 92), {
          anchor: new BMap.Size(50, 50)   // 图标中央下端的尖角位置。
        });
        var marker = new BMap.Marker(pt, {icon: myIcon}); //创建标注
        this.map.addOverlay(marker);
        setTimeout(()=>{
          /*移动到经纬度点*/
          this.map.setViewport([pt])
        },100)
      },
      /*返回群组列表*/
      backbtn() {
        let handleData = this.$store.state.handleData;
        let index = handleData.findIndex(item => item.sosContent.sosProcessorId == this.userInfo.User_ID);
        if(index >= 0){
          this.$store.state.handleData.splice(index,1);
        }
        if(this.$route.name == 'sosPosition'){
          if(this.$store.state.navId == 1){
            this.$router.push(`/intercom/baiduMap`);
          }else if(this.$store.state.navId == 2){
            this.$router.push(`/enclosure/enclosureMap`);
          }else if(this.$store.state.navId == 3){
            this.$router.push(`/promotion/videoInfo`);
          }else{
            this.$router.push(`/history/sosrecord`);
          }
        }else{
          this.$router.go(-1);
        }
      },
       getQueryGroupName(){  //查询群组下拉
            let that = this;
            let data = {
                uId:that.userInfo.id
            }
            queryGroupName(data).then(res =>{
                    if(res.data.code == 200){
                        let list = res.data.data;
                         list.unshift({
                                Cg_ID:-1,
                                Cg_Name:'请选择群组'
                        })
                        this.group = list;
                    }else {
                        that.$MessageWarning(res.data.msg);
                    }
            })
        },
        getQueryUserName(){ //获取用户下拉
            let that = this;
            let data = {
                uId:that.userInfo.id
            }
            queryUserName(data).then(res=>{
                   if(res.data.code == 200){
                        that.user = res.data.data;
                    }else {
                        that.$MessageWarning(res.data.msg);
                    }
            })
        },
        beginEnd(){ //处理结束
           let that = this;
          //  let handleData = this.$store.state.handleData;
          //  let row = handleData.find(item => item.sosContent.sosProcessorId == that.userInfo.User_ID);
          //  if(row){
          // this.$MessageWarning(`已被调度员（${row.sosContent.sosProcessorName}）处理`);
          //               that.isShow = false;
          //               that.ossMap.sosStatus = 1;
          //               that.into(0);
          //     return
          //  }
           axios.put(`${beasconfig.sosUrlapi}-${userState.server}/sos/mg/handle/beginEnd?sosMsgId=${that.ossMap.sos_msg_id}&uid=${that.userInfo.User_ID}`).then(res => {
                        // console.log(res)
                        if(res.data.code == 200){
                          that.isShow = false;
                          that.ossMap.sosStatus = 1;
                          that.into(0);
                        }
                 }).catch(() =>{
                    that.sosMsg(0);
                 })
        },
        search(){   //搜索

        },
        sosMsg(type){   //提示
                axios.get(`${beasconfig.sosUrlapi}-${userState.server}/sos/mg/detail?sosMsgId=${this.ossMap.sos_msg_id}`).then(res1 =>{
                             if(res1.data.code == 200){
                                  if(res1.data.data.sosStatus != 0){
                                    if(type == 0){
                                        this.$MessageWarning(`已被调度员（${res1.data.data.sosProcessorName}）处理`);
                                    }
                                    this.isShow = false;
                                    this.ossMap.sosStatus = res1.data.data.sosStatus;
                                    this.into(0);
                                  }
                                  if(type == 2){
                                    this.into(0);
                                  }
                             }
                        })
        }
    },
    created() {
        this.ossMap = JSON.parse(this.$route.query.ossMap);
        this.isShow = this.ossMap.sosStatus==0?true:false;
        this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        this.getQueryGroupName();
        this.getQueryUserName();
        this.sosMsg();
    },
    beforeRouteEnter(to, from, next) {   //监听页面
        next(vm => {
            if(from.name != 'sosPosition' && from.name != null){
              vm.routeName = from.path;
            }
        })
    },
    watch: {
        '$store.state.handleData'(newVal) {
            //对数据执行操作
            let find = this.$store.state.handleData.find(item => item.sosContent.msgId == this.ossMap.sos_msg_id);
            if (newVal.length > 0) {
                      if(find){
                        this.sosMsg(1)
                      }
            }
        },
        $route(){
              this.ossMap = JSON.parse(this.$route.query.ossMap);
              this.isShow = this.ossMap.sosStatus==0?true:false;
              // this.into(0);
              this.sosMsg(2);
        }
    }
  };
</script>

<style>
.flex-al {
  display: flex;
  position: relative;
}

.nowrap {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.baiduinfo_box {
  /* width: 280px; */
  height: 160px;
  /* position: absolute; */
  background-color: transparent !important;
}

.baiduinfo {
  color: #000;
  /* font-weight: bold; */
  width: 280px;
  font-size: 14px;
  border: 1px solid #e2e2e2;
  background-color: #fff;
  position: absolute;
  bottom: 0px;
  padding: 10px;
  border-radius: 5px;
}

.boldText {
  font-weight: bold;
  font-size: 15px;
  min-width: 40px;
}

.boldText1 {
  flex: 1;
  white-space: break-spaces;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

.baiduinfo>div {
  line-height: 18px;
  margin-bottom: 2px;
  font-weight: bold;
}

.hint {
  color: red;
  font-size: 12px;
}

.address {
  color: #303030;
}

.show {
  width: 300px;
  position: absolute !important;
  left: 290px !important;
  top: -5px;
  display: none;
  background-color: #fff;
  padding: 5px 10px;
  white-space: pre-wrap;
  /* border-radius: 5px; */
}

.htmls {
  position: relative;
}

.htmls:hover .show {
  color: #303030;
  opacity: 1;
  display: block;
}
</style>

<style lang="less" scoped>
.warp {
  height: 100%;

  .cententbox {
    height: calc(100%);

    .persontextbox {
      height: 42px;
      border-bottom: 1px solid #ddd;

      .persontext {
        display: inline-block;
        height: 41px;
        line-height: 41px;
        padding: 0 20px;
        font-size: 15px;
        color: #888;
        border: 1px solid #ddd;
        border-bottom-color: #fff;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
        background-color: #fff;
      }
    }

    .disflex {
      display: flex;
      flex-direction: row;

      &.heaedercl {
        justify-content: space-between;
        background: #f0f0f0;
        padding: 10px 0;
      }

      .fhText {
        padding: 12px 20px;
        color: #fff;
        background-color: #4d4d4d;
        border-radius: 5px;
        margin-left: 15px;
        font-size: 15px;
      }
    }
  }

  .baidu-api {
    width: 100%;
    height: calc(100vh - 75px);
    margin-top: 10px;
  }

}

.head {
  flex: 1;
  background-color: #f0f0f0;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  color: #5a5655;

  &_container {
    flex: 1;
    margin-left: 10%;
  }

  &_dropDown {
    font-size: 15px;
    color: #5a5655;
    margin-right: 20px;

    span {
      margin-right: 5px;
    }
  }

  &_select {
    width: 160px;
    height: 40px;
  }

  &_picker {
    width: 280px !important;
    text-align: center;
  }

  &_search {
    width: 70px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background: url(/img/leftBg1.81e6fd48.png);
    background-size: 100% 100%;
    border-radius: 5px;
    color: #ffffff !important;
  }

  &_but {
    width: 110px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    background-color: #1159ac;
    border-radius: 3px;
    color: #ffffff !important;
    margin-right: 10px;
  }
}


.flex {
  display: flex;
  align-items: center;
}
</style>